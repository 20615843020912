import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Pdf {
  name: string;
  show: boolean;
  error?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  showToast = new Subject<Pdf>();
}
